import { HiLink } from "react-icons/hi2";
import { IoLogoGithub } from "react-icons/io";

const ProjectCard = ({
  picture,
  github = null,
  website,
  title,
  subtitle,
  description,
  tools,
  isImageOnLeft = false,
}) => {
  const orderClass = isImageOnLeft ? "md:flex-row" : "md:flex-row-reverse";

  return (
    <div
      className={`flex flex-col rounded-2xl bg-white/10 backdrop-blur-lg shadow-[0_8px_30px_rgba(255,255,255,0.1)] overflow-hidden transition-all hover:scale-[1.02] ${orderClass}`}
    >
      {/* Left Content (Text) */}
      <div
        data-aos-easing="ease-in-out"
        data-aos="fade-up"
        className="flex flex-col justify-center p-8 md:w-1/2 text-white"
      >
        {/* Icons (GitHub & Website) */}
        <div className={`flex gap-4 ${!isImageOnLeft && "justify-end"}`}>
          {github && (
            <a href={github} className="hover:scale-110 transition-transform">
              <IoLogoGithub className="text-white/80 hover:text-blue-300" size={24} />
            </a>
          )}
          {website && (
            <a href={website} className="hover:scale-110 transition-transform">
              <HiLink className="text-white/80 hover:text-blue-300" size={24} />
            </a>
          )}
        </div>

        {/* Project Title & Subtitle */}
        <h1 className="py-2 text-3xl font-extrabold drop-shadow-md">{title}</h1>
        <h2 className="pb-5 text-2xl font-semibold text-gray-300">{subtitle}</h2>

        {/* Description */}
        <p className="text-gray-300 leading-relaxed">{description}</p>

        {/* Tools */}
        <div className="flex flex-wrap items-center justify-center gap-4 py-6">
          {tools.map((tool) => (
            <img key={tool.id} alt={tool.alt} src={tool.img} className="w-12 h-12 object-contain rounded-lg shadow-md" />
          ))}
        </div>
      </div>

      {/* Right Content (Project Image) */}
      <img
        data-aos-easing="ease-in-out"
        data-aos="fade-up"
        className="md:w-1/2 object-cover rounded-2xl"
        src={picture}
        alt={title}
      />
    </div>
  );
};

export default ProjectCard;
