import React from 'react';
import { HiMail } from "react-icons/hi";
import { IoLogoGithub } from "react-icons/io";
import { BsLinkedin } from "react-icons/bs";
import { SiSpigotmc } from "react-icons/si";
import { FaSquareXTwitter } from "react-icons/fa6";

const SocialLink = ({ href, icon: Icon, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="group flex items-center gap-2 text-white/80 transition-all duration-300
      hover:text-white hover:scale-105"
  >
    <Icon className="text-2xl transition-transform duration-300 group-hover:rotate-6" />
    {label && (
      <span className="text-sm font-medium transition-colors duration-300
        group-hover:text-primary">
        {label}
      </span>
    )}
  </a>
);

const Footer = () => {
  return (
    <footer
      id="contact"
      className="relative mt-20 overflow-hidden bg-gradient-to-b from-blue-900/10 to-blue-900/20
        backdrop-blur-sm"
    >
      <div className="mx-auto max-w-7xl px-4 py-12">
        {/* Main Footer Content */}
        <div className="grid gap-8 md:grid-cols-2 md:gap-12">
          {/* Logo Section */}
          <div className="flex flex-col items-center justify-center md:items-start">
            <a href="#" className="group transition-transform duration-300 hover:scale-105">
              <h2 className="font-impact text-3xl tracking-wide">
                <span className="text-primary">I</span>am
                <span className="text-primary">T</span>he
                <span className="text-primary">D</span>efender
              </h2>
            </a>
          </div>

          {/* Social Links */}
          <div className="flex flex-col items-center gap-6 md:items-end">
            <div className="flex items-center gap-6">
              <SocialLink
                href="https://github.com/IamTheDefender"
                icon={IoLogoGithub}
              />
              <SocialLink
                href="https://twitter.com/iamthedefender_"
                icon={FaSquareXTwitter}
              />
              <SocialLink
                href="https://www.spigotmc.org/members/kepler121.1531997/"
                icon={SiSpigotmc}
              />
            </div>
            <SocialLink
              href="mailto:contact@iamthedefender.xyz"
              icon={HiMail}
              label="contact@iamthedefender.xyz"
            />
          </div>
        </div>

        

        {/* Decorative Elements */}
        <div className="absolute -top-16 left-1/2 h-32 w-32 -translate-x-1/2
          rounded-full bg-primary/20 blur-3xl" />
        <div className="absolute -right-16 top-1/2 h-48 w-48
          rounded-full bg-blue-500/10 blur-3xl" />
      </div>
    </footer>
  );
};

export default Footer;