const SkillCard = ({ title, tools, children }) => {
  return (
    <div
      data-aos-easing="ease-in-out"
      data-aos="zoom-in"
      className="flex flex-col items-center rounded-2xl bg-white/10 p-6 backdrop-blur-lg shadow-[0_8px_30px_rgba(255,255,255,0.1)] transition-all hover:scale-[1.03] hover:shadow-lg text-white"
    >
      {/* Icon / Custom Content */}
      {children}

      {/* Title */}
      <h2 className="pb-6 text-2xl font-bold drop-shadow-md">{title}</h2>

      {/* Tool Icons / Labels */}
      <div className="flex flex-wrap justify-center gap-4">
        {tools.map((tool, index) => 
          typeof tool === "string" ? (
            // Render text-based tools with MacOS-style pill design
            <span
              key={index}
              className="px-4 py-2 rounded-full bg-gradient-to-r from-blue-400 to-purple-500 text-white font-medium shadow-md"
            >
              {tool}
            </span>
          ) : (
            // Render image-based tools with rounded corners
            <img 
              key={tool.id} 
              alt={tool.alt} 
              src={tool.img} 
              className="w-20 h-10 object-contain rounded-lg shadow-md"
            />
          )
        )}
      </div>
    </div>
  );
};

export default SkillCard;
