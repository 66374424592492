import { default as java } from "./assets/icons/java.svg";
import { default as python } from "./assets/icons/python.svg";
import { default as js } from "./assets/icons/js.svg";
import { default as html } from "./assets/icons/html.svg";
import { default as css } from "./assets/icons/css.svg";
import { default as dart } from "./assets/icons/dart.svg";
import { default as cs } from "./assets/icons/cs.svg";
import { default as php } from "./assets/icons/php.svg";
import { default as flutter } from "./assets/icons/flutter.svg";
import { default as react } from "./assets/icons/react.svg";
import { default as redux } from "./assets/icons/redux.svg";
import { default as mui } from "./assets/icons/mui.svg";
import { default as bootstrap } from "./assets/icons/bootstrap.svg";
import { default as blender } from "./assets/icons/blender.svg";
import { default as adobexd } from "./assets/icons/adobexd.svg";
import { default as figma } from "./assets/icons/figma.svg";
import { default as fcm } from "./assets/icons/fcm.svg";
import { default as bash } from "./assets/icons/bash.svg";
import { default as mysql } from "./assets/icons/mysql.svg";
import { default as phpmyadmin } from "./assets/icons/phpmyadmin.svg";
import { default as snyk } from "./assets/icons/snyk.svg";
import { default as linux } from "./assets/icons/linux.svg";
import { default as unity } from "./assets/icons/unity.svg";
import { default as androidstd } from "./assets/icons/androidstd.svg";
import { default as vercel } from "./assets/icons/vercel.svg";
import { default as xampp } from "./assets/icons/xampp.svg";
import { default as tailwindcss } from "./assets/icons/tailwindcss.svg";
import { default as typescript } from "./assets/icons/typescript.svg";
import { default as nodejs } from "./assets/icons/nodejs.svg";
import { default as googlebooksapi } from "./assets/icons/googlebooksapi.svg";
import { default as firebase } from "./assets/icons/firebase.svg";
import { default as stripe } from "./assets/icons/stripe.svg";
import { default as chatgptapi } from "./assets/icons/chatgptapi.svg";
import { default as spigotmc } from "./assets/icons/spigotmc.png";
import { default as bungeecord } from "./assets/icons/bungeecord.png";
import { default as papermc } from "./assets/icons/papermc.webp";
import { default as velocity } from "./assets/icons/velocity.svg";

const data = {
  languages: [
    { id: 1, alt: "Java", img: java },
    { id: 3, alt: "JavaScript", img: js },
    { id: 4, alt: "HTML5", img: html },
    { id: 5, alt: "CSS3", img: css },
    { id: 6, alt: "Python", img: python}
  ],
  lib: [
    { id: 123, alt: "SpigotMC", img: spigotmc},
    { id: 124, alt: "Bungeecord", img: bungeecord},
    {id: 125, alt: "PaperMC", img: papermc},
    {id: 126, alt:"Velocity", img: velocity},
  ],
  design: [
    { id: 16, alt: "Figma", img: figma },
  ],
  tools: [
    { id: 18, alt: "Bash", img: bash },
    { id: 19, alt: "MySQL", img: mysql },
    { id: 20, alt: "phpMyAdmin", img: phpmyadmin },
    { id: 22, alt: "Linux", img: linux },
    { id: 26, alt: "Vercel", img: vercel },
  ],
  project1: [
    { id: 9, alt: "Flutter", img: flutter },
    { id: 6, alt: "Dart", img: dart },
    { id: 8, alt: "PHP", img: php },
    { id: 27, alt: "Xampp", img: xampp },
    { id: 20, alt: "phpMyAdmin", img: phpmyadmin },
    { id: 17, alt: "Firebase Cloud Messaging", img: fcm },
    { id: 24, alt: "Android Studio", img: androidstd },
    { id: 19, alt: "MySQL", img: mysql },
    { id: 15, alt: "Adobe XD", img: adobexd },
  ],
  project2: [
    { id: 10, alt: "React JS", img: react },
    { id: 12, alt: "MUI", img: mui },
    { id: 16, alt: "Figma", img: figma },
    { id: 26, alt: "Vercel", img: vercel },
  ], //bookish
  project3: [
    { id: 10, alt: "React JS", img: react },
    { id: 29, alt: "Tailwind CSS", img: tailwindcss },
    { id: 28, alt: "TypeScript", img: typescript },
    { id: 30, alt: "Node.js", img: nodejs },
    { id: 32, alt: "Google Books API", img: googlebooksapi },
    { id: 31, alt: "Firebase", img: firebase },
    { id: 33, alt: "Stripe", img: stripe },
    { id: 16, alt: "Figma", img: figma },
    { id: 26, alt: "Vercel", img: vercel },
  ], //landgpt
  project4: [
    { id: 10, alt: "React JS", img: react },
    { id: 29, alt: "Tailwind CSS", img: tailwindcss },
    { id: 30, alt: "Node.js", img: nodejs },
    { id: 34, alt: "ChatGPT API", img: chatgptapi },
  ],
  card1: [
    { id: 9, alt: "Flutter", img: flutter },
    { id: 6, alt: "Dart", img: dart },
  ],
  card2: [
    { id: 9, alt: "Flutter", img: flutter },
    { id: 6, alt: "Dart", img: dart },
  ],
  card3: [
    { id: 3, alt: "JavaScript", img: js },
    { id: 4, alt: "HTML5", img: html },
    { id: 13, alt: "Bootstrap", img: bootstrap },
  ],
  card4: [
    { id: 3, alt: "JavaScript", img: js },
    { id: 8, alt: "PHP", img: php },
    { id: 4, alt: "HTML5", img: html },
    { id: 19, alt: "MySQL", img: mysql },
    { id: 13, alt: "Bootstrap", img: bootstrap },
  ],
};

export default data;
