import React, { useState, useEffect } from 'react';
import { X, Menu, ChevronUp } from 'lucide-react';
import { Link, Events, scrollSpy, scroller } from 'react-scroll';

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  const links = [
    { id: 'about', label: 'About' },
    { id: 'experience', label: 'Experience' },
    { id: 'skills', label: 'Skills' },
    { id: 'projects', label: 'Projects' },
    { id: 'contact', label: 'Contact' }
  ];

  useEffect(() => {
    Events.scrollEvent.register('begin', () => {});
    Events.scrollEvent.register('end', () => {});
    scrollSpy.update();

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      
      setShouldShowButton(currentScrollY > 400);
    
      if ((windowHeight + currentScrollY) >= documentHeight - 10) {
        setActiveLink('contact');
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroller.scrollTo('about', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  };

  const handleLinkClick = (id) => {
    setIsMobileMenuOpen(false);
    setActiveLink(id);
  };

  return (
    <>
      {/* Main Navbar */}
      <nav 
        className={`fixed top-4 left-1/2 transform -translate-x-1/2 
          w-[95%] md:w-auto md:min-w-[600px] lg:max-w-[800px] px-4 md:px-6 py-3 
          rounded-full shadow-lg bg-gradient-to-r from-blue-900/90 
          to-blue-800/90 backdrop-blur-md border border-white/10 z-50
          transition-all duration-500 ease-in-out
          ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-20 pointer-events-none'}`}
      >
        <div className="flex items-center justify-between">
          {/* Hide Button */}
          <button
            onClick={() => setIsVisible(false)}
            className="relative group p-1.5 rounded-full hover:bg-white/10 
              transition-all duration-300 hover:rotate-90 transform"
          >
            <X className="w-4 h-4 text-white/80 group-hover:text-white 
              transition-colors duration-300" />
          </button>

          {/* Desktop Menu */}
          <ul className="hidden md:flex items-center gap-6">
            {links.map(({ id, label }) => (
              <li key={id} className="relative">
                <Link
                  to={id}
                  spy={true}
                  smooth={true}
                  duration={800}
                  activeClass="active"
                  className={`relative text-sm font-medium px-2 py-1 rounded-lg
                    transition-all duration-300 ease-in-out cursor-pointer
                    transform hover:scale-110
                    ${activeLink === id ? 'text-white scale-105' : 'text-white/80 hover:text-white'}`}
                  onSetActive={() => setActiveLink(id)}
                >
                  {label}
                  <span className={`absolute inset-x-0 -bottom-1 h-0.5 bg-white
                    transform transition-all duration-300 ease-in-out
                    ${activeLink === id ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'}`} 
                  />
                </Link>
              </li>
            ))}
          </ul>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-white/10 
              transition-all duration-300"
          >
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>

        {/* Mobile Menu Dropdown */}
        <div className={`md:hidden absolute top-full left-0 right-0 mt-4 
          bg-gradient-to-r from-blue-900/95 to-blue-800/95 backdrop-blur-md 
          rounded-2xl shadow-lg border border-white/10 overflow-hidden
          transition-all duration-300 ease-in-out origin-top
          ${isMobileMenuOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0 pointer-events-none'}`}
        >
          <ul className="py-2">
            {links.map(({ id, label }) => (
              <li key={id}>
                <Link
                  to={id}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={800}
                  activeClass="active"
                  className={`block px-6 py-3 text-sm font-medium
                    transition-all duration-300 ease-in-out cursor-pointer
                    ${activeLink === id ? 'bg-white/10 text-white' : 'text-white/80 hover:bg-white/10 hover:text-white'}`}
                  onClick={() => handleLinkClick(id)}
                  onSetActive={() => setActiveLink(id)}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>

      {/* Show Navigation Button */}
      <button
        onClick={() => setIsVisible(true)}
        className={`fixed bottom-6 right-6 p-3 bg-blue-900 text-white rounded-full 
          shadow-lg transition-all duration-500 ease-in-out
          hover:bg-blue-800 hover:scale-110 hover:rotate-180
          focus:outline-none focus:ring-2 focus:ring-white/20 
          focus:ring-offset-2 focus:ring-offset-blue-900 z-50
          ${!isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20 pointer-events-none'}`}
      >
        <Menu className="w-6 h-6 transition-transform duration-300" />
      </button>

      {/* Scroll to Top Button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-6 left-6 p-3 bg-gray-900/80 text-white 
          rounded-full shadow-lg transition-all duration-500 ease-in-out
          hover:bg-gray-800 hover:scale-110 
          focus:outline-none focus:ring-2 focus:ring-white/20 
          focus:ring-offset-2 focus:ring-offset-gray-900 z-50
          ${shouldShowButton ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-20 pointer-events-none'}`}
      >
        <ChevronUp className="w-6 h-6 transition-transform duration-300
          hover:transform hover:-translate-y-1" />
      </button>
    </>
  );
};

export default Navbar;