import { HiOutlineLightBulb } from "react-icons/hi"; // Note Icon
import cosmetics from "../assets/exp/cosmetics.jpg";
import rotations from "../assets/exp/itemrotation.jpg";
import PerArenaGenerator from "../assets/exp/perarenagen.png";
import ProjectCard from "./ui/ProjectCard";
import { IoLogoGithub } from "react-icons/io";

const Projects = () => {
  return (
    <div
      id="projects"
      className="projects mx-auto flex flex-col justify-center gap-12 p-4 text-center md:items-center md:justify-between lg:max-w-7xl"
    >
      <h1 className="py-10 text-3xl font-bold text-primary md:text-4xl">
        Famous Projects
      </h1>

      {/* RECENT PROJECTS */}
      <ProjectCard
        title="BedWars Cosmetics"
        subtitle="SpigotMC Plugin (Java)"
        description="An addon for the supported BedWars plugin that adds various cosmetics such as Sprays, Glyphs, Victory Dances, ShopKeeperSkins, and Kill Messages! Inspired by Hypixel Network."
        picture={cosmetics}
        github="https://github.com/IamTheDefender/BedWars-Cosmetics"
        website="https://www.spigotmc.org/resources/bedwars-cosmetics.106685/"
        tools={[]}
      />
      <ProjectCard
        title="Item Rotation"
        subtitle="SpigotMC Plugin (Java)"
        description="An addon for BedWars1058 (as of Feb. 2024) that introduces a shop category with special rotating items such as unicorn item and ice bridge. Inspired by Hypixel Network."
        picture={rotations}
        github="https://github.com/IamTheDefender/BedWars1058-ItemRotation"
        website="https://www.spigotmc.org/resources/item-rotation-bw1058.107016/"
        tools={[]}
        isImageOnLeft={true}
      />
      <ProjectCard
        title="PerArenaGenerator"
        subtitle="SpigotMC Plugin (Java)"
        description="Adds the ability to have multiple generator speed configurations per group instead of a global setting. Very lightweight."
        picture={PerArenaGenerator}
        website="https://polymart.org/resource/bedwars1058-perarenagen.2815"
        tools={[]}
      />

      {/* 🔥 CHECK OUT OTHER PROJECTS SECTION (MacOS Styled) */}
      <div className="mx-auto w-full max-w-4xl">
        <div className="group relative overflow-hidden rounded-xl border border-white/10 bg-white/5 p-6 backdrop-blur-md transition-all hover:bg-white/[0.07] hover:shadow-[0_4px_14px_rgba(0,0,0,0.15)]">
          <div className="flex flex-col items-center gap-3 text-center">
            <HiOutlineLightBulb className="text-yellow-300/80 transition-all group-hover:text-yellow-300" size={36} />
            <h2 className="text-[17px] font-medium text-white/90">Explore More Projects</h2>
            <p className="text-gray-400/90 text-[15px] leading-6 max-w-md">
              While I've contributed to <strong className="font-semibold text-white/95">100+ client projects</strong>, 
             many remain private. Discover my public work on GitHub!
            </p>
      <a
        href="https://github.com/IamTheDefender"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-1 inline-flex items-center gap-2 rounded-lg bg-gray-200/5 px-4 py-2.5 text-sm font-medium text-white/90 ring-1 ring-white/10 transition-all hover:bg-gray-200/10 hover:ring-white/20"
      >
        <IoLogoGithub className="text-2xl" />
      </a>
    </div>
  </div>
  </div>
  </div>
  );
};

export default Projects;
