const ExperienceCard = ({ logo, name, job, duration, skills }) => {
  return (
    <div className="relative flex w-full flex-col items-center gap-6 rounded-2xl bg-white/10 p-6 backdrop-blur-lg shadow-[0_8px_30px_rgba(255,255,255,0.1)] transition-all hover:scale-[1.02] hover:shadow-lg md:flex-row md:gap-12 md:text-left">
      
      {/* Blob & Logo Container */}
      <div className="relative w-3/4 md:w-1/2 flex justify-center">
        <img className="relative w-1/2 md:max-w-[45%] drop-shadow-lg" src={logo} alt={name} />
      </div>

      {/* Text Content */}
      <div className="w-full px-9 md:flex md:flex-col text-white">
        <div className="space-y-1">
          <h2 className="text-2xl font-bold drop-shadow-md">{job}</h2>
          <p className="text-gray-300">{name} ({duration})</p>
        </div>
        <hr className="my-4 border-0 h-[2px] bg-gradient-to-r from-blue-400 to-purple-400" />
        <ul className="list-disc pl-5 text-gray-300">
          {skills.map((skill, index) => (
            <li key={index} className="leading-tight">
              {skill}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ExperienceCard;